import React, { FunctionComponent, ReactElement } from 'react'
import { useDynamicSVGImport } from '../../services/icon'
import { ReactComponent as DefaultFurnitureIcon } from '../../assets/furniture/default.svg'
import { ReactComponent as DefaultBoxesIcon } from '../../assets/boxes/default.svg'
import styled from '@emotion/styled'

const GenericIcon: FunctionComponent<IconProps> = ({ name, path, ...rest }): ReactElement | null => {
  const { error, loading, SvgIcon } = useDynamicSVGImport(name, path)
  if (error) {
    return path === 'furniture' ? <DefaultFurnitureIcon /> : <DefaultBoxesIcon />
  }
  if (loading) {
    return <div>Loading...</div>
  }
  if (SvgIcon) {
    return <SvgIcon {...rest} />
  }
  return null
}



const Picto = (props: any) => (<div {...props}></div>)


const PictoCustom = styled(Picto)<any>(({ theme }) => {
  return {
    display: 'inline-block',
    lineHeight: 'normal',
    [theme.breakpoints.up('md')]: {
      fontSize: '48px',
      width:'48px'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '64px',
      width:'64px'
    }, [theme.breakpoints.up('xs')]: {
      fontSize: '64px',
      width:'64px'
    },
}
})

const Icon = (props: { item: FurnitureItem | BoxItem, type: string }) => {
  if (props.item.icon) {
    return <PictoCustom className={props.item.icon}/>
  } else {
    return <GenericIcon name={props.item.label.replaceAll(' ', '_')} path={props.type} />
  }
}

export default Icon

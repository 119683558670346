import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App/App'
import './i18n'
import './styles/app.scss'

// Ajouter dynamiquement la balise <link> pour le fichier CSS
const link = document.createElement('link')
link.rel = 'stylesheet'
link.href = 'https://cdn.mobilio.mobi/assets/stylesheets/estimator.css'
document.head.appendChild(link)


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
